import { useState } from 'react';
import { observer } from 'mobx-react';
import { AutoSizer } from 'react-virtualized';
import { FiChevronDown } from 'react-icons/fi';
import { Anchor } from '@strategies/react-anchors';
import { henshu, useHenshu } from '@strategies/henshu';

import Section from '../Section';
import { Callout, Pin } from '../MapIcons/MapIcons';

import sunIcon from '../../assets/map-icons/sun.svg';
import cameraIcon from '../../assets/map-icons/camera.svg';
import globeIcon from '../../assets/map-icons/globe.svg';
import usersIcon from '../../assets/map-icons/users.svg';
import walkPath from '../../assets/map-icons/walk-path.png';


export default observer(function Welcome() {
    const { bindTo } = useHenshu();
    const [promptHidden, setPromptHidden] = useState(false);

    return (
        <div className="Welcome-wrap">
            <Section name="Welcome" index={0}>
                <Anchor onChange={(anchor: any) => setPromptHidden(anchor.placement === 'offscreen')} />

                <div className="links">
                    {/*(}
                    <henshu.richtext {...bindTo('calendar.body')} />
                    */}

                    <henshu.a
                        className="button with-arrow"
                        href={'#survey'}
                        {...bindTo('calendar.button')}
                    />
                </div>

                {/*}<henshu.p {...bindTo('Welcome.body')} />*/}

                <div className='engagement-animation engage'>
                    <Callout term="animation.prompt1"/>
                    <Callout term="animation.prompt2"/>
                    <Pin className="live" alt={''} icon={sunIcon} term="animation.icon1" />
                    <Pin className="work" alt={''} icon={usersIcon} term="animation.icon2" />

                    <div className="connected-pins">
                        <AutoSizer>
                            {({ width, height }) => <>
                                <Pin className="walk" alt={''} icon={globeIcon} term="animation.icon3" />
                                <Pin className="entertainment" alt={''} icon={cameraIcon} term="animation.icon4" />
                                <div className="path-wrapper">
                                    <img src={walkPath} aria-hidden="true" alt="Animated path" style={{ width, height }} />
                                </div>
                            </>}
                        </AutoSizer>
                    </div>
                </div>
            </Section>

            <footer
                className={promptHidden ? 'hidden' : ''}
                onClick={() => window.scrollTo({ top: window.innerHeight - 100, left: 0, behavior: 'smooth' })}
            >
                <FiChevronDown />
            </footer>
        </div>
    );
});
