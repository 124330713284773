import { observer } from 'mobx-react';
import { henshu, useHenshu } from '@strategies/henshu';

import Section from '../Section';
import { ENGAGEMENT_LINK } from '../../config';
import { Resource } from '../Resources/Resources';


export default observer(function Survey() {
    const { bindTo } = useHenshu();

    return (
        <Section index={1} name="Survey">
            <henshu.richtext {...bindTo('survey.body')} />
            <ul>
                <henshu.each {...bindTo('survey.resources_post')}>
                    {(bindToEach, i) => <li key={`SER${i}`}><Resource i={`SER${i}`} bindTo={bindToEach} /></li>}
                </henshu.each>
                <div className='ugh'>
                        <li key={"hhmm"}>
                            <henshu.h2 {...bindTo('hhmm.title')} />
                            <henshu.richtext {...bindTo('hhmm.body')} />
                        </li>

                </div>
                
            </ul>
            <henshu.richtext {...bindTo('survey.secondbody')} />
            <ol className="ugh">
                <henshu.each {...bindTo('survey.events')}>
                    {(bindToEach, i) => (
                        <li key={i}>
                            <henshu.h2 {...bindToEach('title')} />
                            <henshu.richtext {...bindToEach('body')} />
                        </li>
                    )}
                </henshu.each>
            </ol>

            
            
            
        </Section>
    );
});
