const firebaseConfig = {
    apiKey: "AIzaSyDF-YWz3C8sGsEm3vIvyd8S-u9rp5SY3E0",
    authDomain: "unm-engagement.firebaseapp.com",
    projectId: "unm-engagement",
    storageBucket: "unm-engagement.appspot.com",
    messagingSenderId: "534063316707",
    appId: "1:534063316707:web:21af3001588620c427cfd6"
};

export default firebaseConfig;
